import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
import cookie from 'react-cookies';
import languageMapping from '../../../utils/languageMapping';
import ImageLink from '../../../core/Components/ImageLink';

// data
import dataDefault from './default.json';

function getImgProperties(str) {
  const newDiv = document.createElement('div');
  newDiv.innerHTML = str;
  return {
    src: newDiv.getElementsByTagName('img')[0].src,
    width: newDiv.getElementsByTagName('img')[0].width,
    alt: newDiv.getElementsByTagName('img')[0].alt,
  };
}

// pass in obj from array
function getPair(toObject, fromObject) {
  // return new key value pair to new labels object
  return (toObject[fromObject.key.value] = fromObject.value.value);
}

function NotAuthorized(props) {
  const {
    cookieDomain,
    authLogout,
    authRevoke,
    homePath,
    authToken,
    scCd,
    scApiKey,
    routeLocaleCode,
    tokenUserCountryCode,
    tokenUserLocale,
    countryLanguageList,
  } = props;

  const logo = {
    value: {
      alt: 'Cochlear logo',
      width: '666',
      height: '124',
      src: 'https://mss-p-007-delivery.sitecorecontenthub.cloud/api/public/content/5951706822104909882a8112a2fcdb59?v=4a4620fc',
    },
  };

  const logoLink = {
    value: {
      href: 'https://www.cochlear.com/',
    },
  };

  // logout user with token
  // -----------------------
  // revoke token and logout - user has a token but no store claim so log them out after revoking their token
  // or the store endpoint was down

  const [logout, setLogout] = useState(false);
  const logoutUser = () => {
    setLogout(true);
    const token = cookie.load('currentUser');
    const getReferrerUrl = cookie.load('referrerUrl');
    axios
      .post(authRevoke.value, { token })
      .then((response) => {
        // handle success
        console.log('response, successfully revoked: ', response);
      })
      .catch((error) => {
        // handle error
        console.log('error, unsuccessfully revoked: ', error);
      })
      .then(() => {
        cookie.remove('currentUser', {
          path: '/',
          domain: cookieDomain.value,
        });
        window.location.replace(
          `${authLogout.value}?logoutURL=${
            getReferrerUrl || 'https://www.cochlear.com/'
          }`,
        );
      });
  };

  const [errorResponse, setErrorResponse] = useState(null);
  const [errorData, setErrorData] = useState({});
  const [errorType, setErrorType] = useState('');

  useEffect(() => {
    // taken from user token
    const language = languageMapping(
      tokenUserLocale,
      tokenUserCountryCode,
      countryLanguageList,
    );

    // GQL query to return the screen image/message and button labels
    const queryData = JSON.stringify({
      query: `
		{
			labels: item(path: "/sitecore/content/drx/${tokenUserCountryCode.toUpperCase()}/Configuration/Error data/labels", language: "${language}") {
				results: children {
					key: field(name: "key") {
						value
					}
					value: field(name: "value") {
						value
					}
				}
			}
			screens: item(path: "/sitecore/content/drx/${tokenUserCountryCode.toUpperCase()}/Configuration/Error data/screens", language: "${language}") {
				results: children {
					name
					items: children {
						name
						title: field(name: "title") {
							value
						}
						text: field(name: "text") {
							value
						}
						image: field(name: "image") {
							value
						}
					}
				}
			}
		}
		`,
    });

    const errorScreensUrl = `https://${scCd}/api/cochlear?sc_apikey=${scApiKey}`;
    axios({
      method: 'post',
      url: errorScreensUrl,
      headers: {
        Authorization: authToken && `Basic ${authToken}`,
        'Content-Type': 'application/json',
      },
      data: queryData,
    })
      .then((result) => {
        const { data } = result.data;
        setErrorData(data);
        setErrorType('countryMismatch');
      })
      .catch((error) => {
        console.log(error);
        setErrorType('networkError');
        setErrorResponse(error && error.response ? error.response.status : '');
      });
  }, [tokenUserCountryCode, routeLocaleCode, authToken, scCd, scApiKey]);

  // used when the api call to fetch error screens fails
  const { screens: defaultData, labels: defaultLabels } = dataDefault.data;

  const errorDataExists = errorData && Object.keys(errorData).length;
  let screens = {};
  let labels = {};
  // data retrieved from get call
  if (errorDataExists) {
    screens = errorData.screens;
    labels = errorData.labels;
  }

  let screenData = {};
  const screenLabels = {};

  if (errorType && errorType !== 'networkError' && errorDataExists) {
    const resultType = screens.results.find((obj) => obj.name === errorType);
    const resultApp = resultType.items.find((obj) => obj.name === 'dcx');

    // create labels oject of strings
    // loop through array
    labels.results.forEach((value) => getPair(screenLabels, value));
    screenData = resultApp || {};
  } else {
    // Do stuff with value or index
    defaultLabels.results.forEach((value) => getPair(screenLabels, value));
    screenData = defaultData.results[0].items[0];
  }

  const { image, title, text } = screenData;
  const imageValue = image.value;
  // object values for the image
  const getImg = imageValue && getImgProperties(imageValue);

  // empty screen until errorType is defined
  if (!errorType) {
    return <div className="main--not-authorized" />;
  }

  // empty screen data. This means the SC Content returned from the query data is empty or missing.
  if (Object.keys(screenData).length === 0) {
    return null;
  }

  return (
    <div className="main--not-authorized">
      <header>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <ImageLink imageField={logo} linkField={logoLink} />
          </div>
        </nav>
      </header>

      <div className="error-message error-message--authorizer">
        <div className="error-message__content">
          <figure className="error-message__image">
            <img
              src={getImg.src}
              alt={getImg.alt}
              style={{ width: getImg.width }}
            />
          </figure>
          <h2 className="error-message__title">{title.value}</h2>
          <p
            className="error-message__text"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text.value),
            }}
          />

          {/* display error code */}
          {errorType === 'networkError' && errorResponse && (
            <p className="error-message__text error-message__text--code">
              <b>{`${screenLabels.codeLabel}:`}</b>
              &nbsp;
              {errorResponse}
            </p>
          )}
        </div>

        {/* #3 - any user / not your country - < take me home | log me out */}
        {/* Default - Network Error - Cochlear home > */}

        <div className="error-message__button-control">
          {errorType !== 'networkError' ? (
            <>
              <a
                href={homePath}
                className="sdw__button sdw__button--cochlear-yellow sdw__button--has-icon-left"
              >
                <span>{screenLabels.takeMeHomeLabel}</span>
              </a>
              <button
                type="button"
                onClick={() => logoutUser()}
                className={`sdw__button sdw__button--outline-purple sdw__button--has-icon-right button-logout has-spinner ${
                  logout ? 'is-submitting' : ''
                }`}
              >
                <span>{screenLabels.logMeOutLabel}</span>
                {/* is submitting spinner  */}
                <div className="lds-ring">
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            </>
          ) : (
            <a
              href="/"
              className="sdw__button sdw__button--cochlear-yellow sdw__button--has-icon-right"
            >
              <span>{screenLabels.homeLabel}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotAuthorized;
