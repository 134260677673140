import React from "react";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import LoginLink from "../LoginLink/LoginLink";
import Link from "../../../../../core/Components/Link";

function TopNav({
  fields,
  siteObject,
  toggleCountrySelector,
  isActiveDropdownNav,
  showPopOver,
  isLoginEnabled,
  isProfileEnabled,
  loginPath,
  isUserLoggedIn,
  callbackClassDropdownNav,
  callbackWhatsNew,
  callbackWhatsNewContent,
  callbackShowPopOver,
}) {
  return (
    <div className="top-nav">
      <div className="l-padding">
        <div className="type-selector">
          <Link className="tab-home" field={fields.homeLink} />
          {fields.professionalsLink.value.text && (
            <Link
              className="tab-professionals"
              field={fields.professionalsLink}
            />
          )}
        </div>
        <div className="nav-selector">
          {fields.topNavList.map(({ fields, id }) => (
            <Link key={id} field={fields.link} />
          ))}
          <LanguageDropdown
            fields={fields}
            toggleCountrySelector={toggleCountrySelector}
            isProfileEnabled={isProfileEnabled}
            isUserLoggedIn={isUserLoggedIn}
          />
          <LoginLink
            cookieDomain={fields.COCHLEAR_COOKIE_DOMAIN.value}
            authUHDropdownMenu={fields.AUTH_UH_DROPDOWN_MENU.value}
            authRevoke={fields.AUTH_REVOKE.value}
            authAuthorize={fields.AUTH_AUTHORIZE.value}
            authLogout={fields.AUTH_CIM_RECIPIENT_LOGOUT.value}
            drxMain={fields.COCHLEAR_DRX_MAIN.value}
            storeLogout={fields.STORE_LOGOUT.value}
            cochlearMCR={fields.COCHLEAR_MCR.value}
            authorizeApp={fields.AUTHORIZE_USER_APP.value}
            authorizeAppAuthorizePath={
              fields.AUTHORIZE_USER_APP_AUTHORIZE_PATH.value
            }
            showProfileLogo={fields.showProfileLogo.value}
            showGivenNameLast={fields.showGivenNameLast.value}
            loginLink={fields.loginLink}
            isActiveDropdownNav={isActiveDropdownNav}
            showPopOver={showPopOver}
            isLoginEnabled={isLoginEnabled}
            isProfileEnabled={isProfileEnabled}
            loginPath={loginPath}
            isUserLoggedIn={isUserLoggedIn}
            callbackClassDropdownNav={callbackClassDropdownNav}
            callbackWhatsNew={callbackWhatsNew}
            callbackWhatsNewContent={callbackWhatsNewContent}
            callbackShowPopOver={callbackShowPopOver}
            siteObject={siteObject}
          />
        </div>
      </div>
    </div>
  );
}

export default TopNav;
